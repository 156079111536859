import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { FileH, FileU } from '../_models';
import { TypeFile } from '../_models/enum/TypeFile';
import { Patient } from '../_models/Patient';

@Injectable({
  providedIn: 'root',
})
export class GenericService {
  constructor(private http: HttpClient) {}

  Meet(nrOrdine: number): Observable<any> {
    let url = `${environment.host}/u/${nrOrdine}/meetings/url`;
    return this.http.get<any>(url);
  }

  PatientsFromNumber(phoneNumber: string): Observable<Patient[]> {
    let url = `${environment.host}/u/patient/${phoneNumber}`;
    return this.http.get<any>(url);
  }

  UserInfo(phoneNumber: string): Observable<any> {
    let url = `${environment.host}/u/patient/${phoneNumber}/info`;
    return this.http.get<any>(url);
  }
}
