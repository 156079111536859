<footer id="x-footer">
    <div class="container">
        <div class="row">
            <div class="d-flex justify-content-center">
                <a role="button" data-toggle="collapse" href="#humanitas_data" aria-expanded="false"
                    aria-controls="humanitas_data">
                    Humanitas Group - Dati societari
                </a>
                <a href=" https://www.humanitas.it/wp-content/uploads/policy/Privacy-Policy_Humanitas.pdf"
                    target="_blank">Privacy Policy</a>

                <a href="https://humanitas.it/wp-content/uploads/policy/Cookie-Policy_Prenota-humanitas.pdf"
                    target="_blank">Cookie-policy</a>
            </div>
        </div>
    </div>
</footer>