import { HttpClient } from '@angular/common/http';
import { inject, Injectable, NgZone } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';
import {
  BehaviorSubject,
  Observable,
  ReplaySubject,
  Subject,
  catchError,
  filter,
  map,
  mergeMap,
  switchMap,
  throwError,
} from 'rxjs';
import { Constants, LocalStoragerService, MyToastrService } from '../_utility';
import { User } from '../_models';
import { environment } from 'src/environments/environment';
import { GlobalPubSubService } from './global-pub-sub.service';
import { GigyaService } from './gigya.service';
import { ProfileService } from './profile.service';
import {
  Auth,
  onAuthStateChanged,
  User as FirebaseUser,
  inMemoryPersistence,
} from '@angular/fire/auth';
import { AuthProvider } from '../_models/enum/AuthProvider';

declare let gigya: any;

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  public authProvider?: AuthProvider;
  public userData: any;
  private userLogged = new Subject<User | boolean>();
  public nrOrdine!: string;
  private firebaseUser?: FirebaseUser | null;
  private auth: Auth = inject(Auth);
  public patientId?: string;
  public logged: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public gigyaProcess: ReplaySubject<boolean> = new ReplaySubject(1);
  public id: string = this.generateRandomString();

  constructor(
    public router: Router,
    private toast: MyToastrService,
    public lStorager: LocalStoragerService,
    private helperJwt: JwtHelperService,
    private route: ActivatedRoute,
    public ngZone: NgZone, // NgZone service to remove outside scope warning
    private globalPubSub: GlobalPubSubService,
    private gigyaService: GigyaService,
    private profileService: ProfileService
  ) {
    this.lStorager.removeElement(Constants.Auth.USER_KEY);
    this.firebaseInit();
    globalPubSub.subscribe('gigyaServiceReady', this.onGigyaServiceReady, this);
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    this.nrOrdine = urlParams.get('nrOrdine')!;
  }

  firebaseInit() {
    this.auth.setPersistence(inMemoryPersistence).then((res) => {
      onAuthStateChanged(
        this.auth,
        (firebaseUser) => {
          this.firebaseUser = firebaseUser;
          if (firebaseUser) {
            const userData: User = {
              uid: firebaseUser.uid,
              email: '',
              firstName: '',
              lastName: '',
            };
            this.user = userData;
            this.authProvider = AuthProvider.FIREBASE;
            this.userLogged.next(userData);
            this.logged.next(true);
          }
        },
        (error) => {
          console.log(error);
        }
      );
    });
  }

  setUserInfo(info: any) {
    let obj: any = localStorage.getItem(Constants.Auth.USER_KEY);
    let user: User = JSON.parse(obj);
    user.firstName = info.patientName;
    user.lastName = info.patientSurname;
    user.email = info.patientMail;
    localStorage.setItem(Constants.Auth.USER_KEY, JSON.stringify(user));
  }

  getProfile(context: any, userData: User) {
    context.profileService.Profile().subscribe({
      next: (res: any) => {
        var obj: any = localStorage.getItem(Constants.Auth.USER_KEY);
        var user = JSON.parse(obj);
        user.canUploadDicom = res.canUploadDicom;
        localStorage.setItem(Constants.Auth.USER_KEY, JSON.stringify(user));
        context.userLogged.next(userData);
      },
      error: (err: any) => {
        console.log(err);
        context.router.navigate(Constants.Routing.MISSING.routerLink);
      },
    });
  }

  isLogged(): Observable<boolean> {
    return this.userLogged.pipe(
      map((x) => Boolean(x) || this.auth.currentUser != null)
    );
  }

  isValidOrdine(nrOrdine: string): Observable<boolean> {
    return this.logged.asObservable().pipe(
      filter((value) => value === true),
      switchMap(() =>
        this.profileService.Profile(nrOrdine).pipe(
          map((res) => {
            var obj: any = localStorage.getItem(Constants.Auth.USER_KEY);
            var user = JSON.parse(obj);
            user.canUploadDicom = res.canUploadDicom;
            localStorage.setItem(Constants.Auth.USER_KEY, JSON.stringify(user));
            this.userLogged.next(user);
            return true;
          }),
          catchError((err: any) => {
            console.log(err);
            this.router.navigate(Constants.Routing.MISSING.routerLink);
            return throwError(() => err);
          })
        )
      )
    );
  }

  onGigyaServiceReady() {
    let context = this;
    var params: any = {};
    params.callback = function (response: any) {
      if (response.errorCode == 0) {
        const userData: User = {
          uid: response.UID,
          email: response.profile.email,
          firstName: response.profile.firstName,
          lastName: response.profile.lastName,
        };
        localStorage.setItem(Constants.Auth.USER_KEY, JSON.stringify(userData));
        var params: any = {};
        params.fields = 'profile.email,profile.firstName,UID';
        params.expiration = 18000;
        params.callback = function (jetObj: any) {
          var obj: any = localStorage.getItem(Constants.Auth.USER_KEY);
          var user = JSON.parse(obj);
          user.token = jetObj.id_token;
          localStorage.setItem(Constants.Auth.USER_KEY, JSON.stringify(user));
          context.authProvider = AuthProvider.GIGYA;
          context.userLogged.next(userData);
          context.logged.next(true);
          context.gigyaProcess.next(true);
          // context.getProfile(context, userData);
        };
        gigya.accounts.getJWT(params);
      } else {
        context.userLogged.next(false);
        context.gigyaProcess.next(true);
        context.logged.next(false || context.firebaseUser != undefined);
        console.log('Error :' + response.errorMessage);
        // context.router.navigate(Constants.Routing.UNAUTH.routerLink);
      }
    };
    gigya.accounts.getAccountInfo(params);
    gigya.accounts.addEventHandlers({
      onLogin: function (event: any) {
        console.log('login event', event);
      },
      onLogout: function () {
        console.log('logout event');
        localStorage.removeItem(Constants.Auth.USER_KEY);
      },
    });
  }

  // Ritorna utente salvato nello storage
  get user(): User {
    const element = this.lStorager.getElement(Constants.Auth.USER_KEY);
    const user = element ? element : null;
    return user;
  }

  set user(user: User | null) {
    if (!user) {
      this.lStorager.removeElement(Constants.Auth.USER_KEY);
    } else {
      this.lStorager.setElement(Constants.Auth.USER_KEY, user);
    }
  }

  // Ritorna true quando utente è loggato e l'email è verifcato
  get isLoggedIn(): boolean {
    return !!this.user && !!this.token ? true : false;
  }

  firebaseToken() {
    return this.firebaseUser!.getIdToken(/* forceRefresh */ true);
  }

  // Ritorna il token o stringa vuota
  get token(): string {
    if (this.authProvider == AuthProvider.GIGYA) {
      return this.user && this.user.token ? this.user.token : '';
    } else {
      return this.user && this.user.token ? this.user.token : '';
    }
  }

  // Sign in with email/password
  SignIn() {
    return this.gigyaService.login();
  }

  // Sign out
  SignOut() {
    this.gigyaService.logout();
    return this.router.navigate(Constants.Routing.LOGIN.routerLink);
  }
  generateRandomString(length = 5) {
    const characters =
      'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let result = '';
    for (let i = 0; i < length; i++) {
      const randomIndex = Math.floor(Math.random() * characters.length);
      result += characters[randomIndex];
    }
    return result;
  }
}
