import {
  ChangeDetectorRef,
  Component,
  HostListener,
  OnInit,
} from '@angular/core';

import {
  NavigationCancel,
  NavigationEnd,
  NavigationError,
  NavigationStart,
  Router,
  Event,
} from '@angular/router';
import { Constants, LocaleService } from './_utility';
import { AuthService } from './_services';
import { User } from './_models';
import { SpinnerService } from './_components/spinner/spinner.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
})
export class AppComponent implements OnInit {
  logged = false;
  public spinner = false;
  spinnerSubscription: Subscription;
  title = 'humanitas';
  getScreenWidth: any;
  hideMenu: boolean = false;

  constructor(
    private router: Router,
    private locale: LocaleService,
    private authService: AuthService,
    private spinnerService: SpinnerService,
    private cdRef: ChangeDetectorRef
  ) {
    this.spinner = true;
    this.spinnerSubscription = this.spinnerService.spinnerObservable.subscribe(
      (flag) => {
        this.spinner = flag;
        this.cdRef.detectChanges();
      }
    );
    this.locale.init();

    authService.isLogged().subscribe({
      next: (res: boolean) => {
        this.logged = res;
        this.spinnerService.hide();
        this.cdRef.detectChanges();
      },
      //error: (err: any) => {
      //  this.router.navigate(Constants.Routing.UNAUTH.routerLink);
      //},
    });
  }

  @HostListener('window:resize', ['$event'])
  onWindowResize() {
    // non utilizzato
    this.getScreenWidth = window.innerWidth;
    if (this.getScreenWidth <= 1025) {
      this.hideMenu = true;
    }
  }

  ngOnInit(): void {}
}
