import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root',
})
export class ProfileService {
  private readonly nrOrdine: string;

  constructor(private apiService: ApiService) {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    this.nrOrdine = urlParams.get('nrOrdine')!;
  }

  Profile(nrOrdine?: string): Observable<any> {
    let endpoint;
    if (nrOrdine) {
      endpoint = `${nrOrdine}/user/login`;
    } else {
      endpoint = `${this.nrOrdine}/user/login`;
    }
    return this.apiService.get(endpoint);
  }
}
