import { Injectable } from '@angular/core';
import { JwtHelperService } from '@auth0/angular-jwt';
import { User } from '../_models';
import { Constants, LocalStoragerService } from '../_utility';

declare let gigya: any;

@Injectable({
  providedIn: 'root',
})
export class GigyaService {
  constructor(
    private helperJwt: JwtHelperService,
    public lStorager: LocalStoragerService
  ) {}

  // Ritorna utente salvato nello storage
  get user(): User {
    const element = this.lStorager.getElement(Constants.Auth.USER_KEY);
    const user = element ? element : null;
    return user;
  }

  set user(user: User | null) {
    if (!user) {
      this.lStorager.removeElement(Constants.Auth.USER_KEY);
    } else {
      this.lStorager.setElement(Constants.Auth.USER_KEY, user);
    }
  }

  // Ritorna true quando utente è loggato e l'email è verifcato
  get isLoggedIn(): boolean {
    return !!this.user && !!this.token ? true : false;
  }

  // Ritorna il token o stringa vuota
  get token(): string {
    return this.user && this.user.token ? this.user.token : '';
  }

  isExpiredToken(): boolean {
    return this.helperJwt.isTokenExpired(this.token);
  }

  login(): any {
    return gigya.accounts.showScreenSet({
      startScreen: 'gigya-televisite-login-screen',
      screenSet: 'Default-RegistrationLogin',
    });
  }

  logout() {
    gigya.accounts.logout({ forceProvidersLogout: true });
  }
}
