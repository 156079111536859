import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Lang } from '../_models';
import { PrimeNGConfig } from 'primeng/api';
import { AuthService } from '../_services';
import { Observable, ReplaySubject, Subject } from 'rxjs';
import { Constants } from './Constants';

@Injectable({
  providedIn: 'root',
})
export class LocaleService {
  readonly DEFAULT_LANG_VALUE = 'it';
  readonly ENG = 'en';
  readonly RUS = 'ru';
  readonly allowLanguages = [this.DEFAULT_LANG_VALUE, this.ENG, this.RUS];
  private lang = new ReplaySubject<string>();

  constructor(
    private translate: TranslateService,
    private config: PrimeNGConfig
  ) {}

  init() {
    let lang = this.translate.getBrowserLang();
    const element = localStorage.getItem(Constants.Lang.LANG);
    if (element && this.allowLanguages.includes(element)) {
      lang = element;
    }
    if (lang && this.allowLanguages.includes(lang)) {
      this.translate.setDefaultLang(lang);
    } else {
      lang = this.DEFAULT_LANG_VALUE;
      this.translate.setDefaultLang(this.DEFAULT_LANG_VALUE);
    }
    this.lang.next(lang);
    this.changeLanguage(lang);
    this.translate
      .get('primeng')
      .subscribe((res) => this.config.setTranslation(res));
  }

  changeLanguage(lang: Lang | string) {
    this.translate.use(lang);
    this.translate
      .get('primeng')
      .subscribe((res) => this.config.setTranslation(res));
    localStorage.setItem(Constants.Lang.LANG, lang);
  }

  getLang(): Observable<string> {
    return this.lang;
  }
}
