import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Router } from '@angular/router';
import { Constants } from '../Constants';
import { MyToastrService } from '..';
import { TranslateService } from '@ngx-translate/core';
// import { AuthenticationService } from '../authentication.service';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  constructor(
    private router: Router,
    private toastr: MyToastrService,
    private translate: TranslateService
  ) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      catchError((err) => {
        console.log(err);
        const error = err.error?.message || err.error?.status;
        if (request.url.includes('/auth')) {
          return throwError(() => error);
        }
        let msg = this.translate.instant(`errors.${err.error?.message}`);
        console.log(msg);
        this.toastr.ERROR(
          Constants.TITLE_ERROR,
          `errors.${err.error?.message}`
        );
        return throwError(() => error);
      })
    );
  }
}
