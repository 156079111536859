import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard, OrdineGuard, Constants, HomepageGuard } from './_utility';
import { LayoutComponent } from './layout/layout.component';

const routes: Routes = [
  {
    path: Constants.Routing.HOMEPAGE.path,
    loadChildren: () =>
      import('./layout/homepage/homepage.module').then(
        (mod) => mod.HomepageModule
      ),
    canActivate: [HomepageGuard],
  },
  {
    path: Constants.Routing.DASHBOARD.path,
    loadChildren: () =>
      import('./layout/dashboard/dashboard.module').then(
        (mod) => mod.DashboardModule
      ),
    canActivate: [AuthGuard],
  },
  {
    path: Constants.Routing.MEETINGS.path,
    loadChildren: () =>
      import('./layout/meeting-list/meeting-list.module').then(
        (mod) => mod.MeetingModule
      ),
    canActivate: [AuthGuard],
  },
  {
    path: Constants.Routing.FILE_RICEVUTI.path,
    loadChildren: () =>
      import('./layout/file-ricevuti/file-ricevuti.module').then(
        (mod) => mod.FileRicevutiModule
      ),
    canActivate: [AuthGuard, OrdineGuard],
  },
  {
    path: Constants.Routing.FILE_INVIATI.path,
    loadChildren: () =>
      import('./layout/file-inviati/file-inviati.module').then(
        (mod) => mod.FileInviatiModule
      ),
    canActivate: [AuthGuard, OrdineGuard],
  },
  {
    path: Constants.Routing.MEETING.path,
    loadChildren: () =>
      import('./layout/meeting/meeting.module').then(
        (mod) => mod.MeetingModule
      ),
    canActivate: [AuthGuard, OrdineGuard],
  },
  {
    path: Constants.Routing.INVIA_FILE.path,
    loadChildren: () =>
      import('./layout/invia-file/invia-file.module').then(
        (mod) => mod.InviaFileModule
      ),
    canActivate: [AuthGuard, OrdineGuard],
  },
  {
    path: Constants.Routing.LOGIN.path,
    loadChildren: () =>
      import('./login/login.module').then((mod) => mod.LoginModule),
  },
  {
    path: Constants.Routing.NOT_FOUND.path,
    loadChildren: () =>
      import('./not-found/not-found.module').then((mod) => mod.NotFoundModule),
  },
  {
    path: Constants.Routing.UNAUTH.path,
    loadChildren: () =>
      import('./unauth/unauth.module').then((mod) => mod.UnauthModule),
  },
  {
    path: Constants.Routing.MISSING.path,
    loadChildren: () =>
      import('./missing-appointment/missing-appointment.module').then(
        (mod) => mod.MissingAppointmentModule
      ),
  },
  { path: '**', redirectTo: '/not_found' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
