import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
} from '@angular/common/http';
import { Observable, bindCallback, from, switchMap } from 'rxjs';
import { AuthService } from '../../_services';
import { Constants } from '../Constants';
import { GigyaService } from 'src/app/_services/gigya.service';
import { AuthProvider } from 'src/app/_models/enum/AuthProvider';
declare let gigya: any;

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
  constructor(
    private gigyaService: GigyaService,
    public authService: AuthService
  ) {}
  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    if (this.authService.authProvider == AuthProvider.GIGYA) {
      if (this.gigyaService.token && this.gigyaService.isExpiredToken()) {
        return from(this.observableFunction()).pipe(
          switchMap((x: any) => {
            let newHeaders = request.headers
              .set('Authorization', 'Bearer ' + this.gigyaService.token!)
              .set('AuthorizationType', this.authService.authProvider!);
            const authReq = request.clone({
              headers: newHeaders,
            });
            return next.handle(authReq);
          })
        );
      } else {
        let token = this.gigyaService.token;
        let newHeaders = request.headers
          .set('Authorization', 'Bearer ' + this.gigyaService.token!)
          .set('AuthorizationType', this.authService.authProvider!);

        if (token) {
          request = request.clone({
            headers: newHeaders,
          });
        }
        return next.handle(request);
      }
    } else if (this.authService.authProvider == AuthProvider.FIREBASE) {
      return from(this.authService.firebaseToken()).pipe(
        switchMap((x: any) => {
          let newHeaders = request.headers
            .set('Authorization', 'Bearer ' + x)
            .set('AuthorizationType', this.authService.authProvider!);
          if (this.authService.patientId) {
            newHeaders = newHeaders.set(
              'PatientId',
              this.authService.patientId
            );
          }
          const authReq = request.clone({
            headers: newHeaders,
          });
          return next.handle(authReq);
        })
      );
    } else {
      return next.handle(request);
    }
  }

  functionWithCallback(callback: (x: any) => void) {
    var params: any = {};
    params.fields = 'profile.email,profile.firstName,UID';
    params.expiration = 18000;
    params.callback = callback;
    // Simulate an asynchronous operation
    gigya.accounts.getJWT(params);
  }

  observableFunction() {
    return new Observable((observer) => {
      this.functionWithCallback((result) => {
        if (result) {
          var obj: any = localStorage.getItem(Constants.Auth.USER_KEY);
          var user = JSON.parse(obj);
          user.token = result.id_token;
          localStorage.setItem(Constants.Auth.USER_KEY, JSON.stringify(user));
          observer.next(result);
          observer.complete();
        } else {
          observer.error('An error occurred');
        }
      });
    });
  }
}
