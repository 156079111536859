export class Constants {
  public static TITLE_INFO = 'INFO!';
  public static TITLE_ERROR = 'ERROR!';
  public static TITLE_WARNING = 'WARNING!';
  public static TITLE_SUCCESS = 'SUCCESS!';
  public static TITLE_OFFLINE = 'IS OFFLINE!';

  static Routing = class {
    public static HOMEPAGE = {
      label: 'Homepage',
      path: '',
      routerLink: ['/'],
    };
    public static ORDER = {
      label: 'Order',
      path: '',
      routerLink: ['/order'],
    };
    public static LOGIN = {
      label: 'Login',
      path: 'login',
      routerLink: ['/login'],
    };
    public static DASHBOARD = {
      label: 'Dashboard',
      path: 'dashboard',
      routerLink: ['/dashboard'],
    };
    public static FILE_RICEVUTI = {
      label: 'File Ricevuti',
      path: 'file-ricevuti',
      routerLink: ['/file-ricevuti'],
    };
    public static FILE_INVIATI = {
      label: 'File Inviati',
      path: 'file-inviati',
      routerLink: ['/file-inviati'],
    };
    public static INVIA_FILE = {
      label: 'Invia File',
      path: 'invia-file',
      routerLink: ['/invia-file'],
    };
    public static MEETING = {
      label: 'Meeting',
      path: 'meet',
      routerLink: ['/meet'],
    };
    public static MEETINGS = {
      label: 'Meetings',
      path: 'meetings',
      routerLink: ['/meetings'],
    };
    public static NOT_FOUND = {
      label: 'Not_found',
      path: 'not_found',
      routerLink: ['/not-found'],
    };
    public static UNAUTH = {
      label: 'Unauthorized',
      path: 'unauthorized',
      routerLink: ['/unauthorized'],
    };
    public static MISSING = {
      label: 'Missing',
      path: 'missing-order',
      routerLink: ['/missing-order'],
    };
  };

  static Auth = class {
    public static USER_KEY = 'user';
    public static ENV_KEY = 'environment';
  };

  static Storager = class {
    public static USER_KEY = 'user';
  };

  static Lang = class {
    public static LANG = 'lang';
  };
}
