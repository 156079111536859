import {
  Component,
  ElementRef,
  OnInit,
  ViewEncapsulation,
} from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/_services';
import { Constants, LocaleService } from 'src/app/_utility';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class HeaderComponent implements OnInit {
  public showMobileMenu: boolean = false;
  public lang: string = 'it';
  public langAC: string = 'ITA';
  languages: { [id: string]: string } = {
    it: 'ITA',
    ru: 'RUS',
    en: 'ENG',
  };

  constructor(
    public auth: AuthService,
    private locale: LocaleService,
    private el: ElementRef
  ) {}

  ngOnInit(): void {
    this.locale.getLang().subscribe({
      next: (l: string) => {
        this.lang = l;
        this.langAC = this.languages[l];
      },
    });
  }

  changeLanguage(lang: string) {
    if (lang == 'it') {
      this.langAC = 'ITA';
    } else if (lang == 'en') {
      this.langAC = 'ENG';
    } else if (lang == 'ru') {
      this.langAC = 'RUS';
    }
    this.lang = lang;
    this.locale.changeLanguage(lang);
  }

  showMenu() {
    this.showMobileMenu = true;
  }

  SingOut() {
    this.auth.SignOut();
  }
}
function ViewChild(
  arg0: string,
  arg1: { static: boolean }
): (target: HeaderComponent, propertyKey: 'op') => void {
  throw new Error('Function not implemented.');
}
