import {
  HttpClient,
  HttpHeaders,
  HttpParams,
  HttpResponse,
} from '@angular/common/http';
import { Injectable, NgZone } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { FileH, FileU } from '../_models';
import { TypeFile } from '../_models/enum/TypeFile';
import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root',
})
export class FileService {
  private readonly nrOrdine: string;

  constructor(private http: HttpClient, private apiService: ApiService) {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    this.nrOrdine = urlParams.get('nrOrdine')!;
  }

  Files(flag: TypeFile): Observable<Array<FileH>> {
    let endpoint = `${this.nrOrdine}/files?type=${flag}`;
    return this.apiService.get<Array<FileH>>(endpoint);
  }

  File(id: string, flag: TypeFile): Observable<Array<FileU>> {
    let endpoint = `${this.nrOrdine}/files/${id}?type=${flag}`;
    return this.apiService.get<Array<FileU>>(endpoint);
  }

  DownloadFile(url: string): any {
    return this.http.get(url, { responseType: 'blob' });
  }

  DeleteFile(id: string): Observable<any> {
    let endpoint = `${this.nrOrdine}/files/${id}`;
    return this.apiService.delete<any>(endpoint);
  }

  getUploadFile(files: any): Observable<any> {
    let endpoint = `${this.nrOrdine}/files/upload_url/generic`;
    return this.apiService.get<Array<FileU>>(endpoint, {
      params: { files: files },
    });
  }

  UploadFile(url: string, file: File): Observable<any> {
    return this.http.put<any>(url, file, {
      headers: {
        'Content-Type': file.type,
        'X-Goog-Content-Length-Range': '0,26214400',
      },
    });
  }

  EndUploadFile(files: Array<string>): Observable<any> {
    let endpoint = `${this.nrOrdine}/files/end_upload/generic`;
    return this.apiService.post<Array<any>>(endpoint, { files: files });
  }

  // alla fine di tutto ho caricato i file
  getUploadFileDICOM(files: any): Observable<any> {
    let endpoint = `${this.nrOrdine}/files/upload_url/dicom`;
    return this.apiService.post<any>(endpoint, { files: files });
  }

  UploadFileDICOM(url: string, file: File): Observable<any> {
    return this.http.put<any>(url, file, {
      headers: {
        'Content-Type': file.type,
      },
    });
  }

  EndUploadFileDICOM(dicomName: string): Observable<any> {
    let endpoint = `${this.nrOrdine}/files/end_upload/dicom`;
    return this.apiService.post<any>(endpoint, { dicom_name: dicomName });
  }
}
