import { inject, Injectable } from '@angular/core';
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  Router,
  UrlTree,
} from '@angular/router';
import { catchError, map, Observable, switchMap, take, tap } from 'rxjs';
import { AuthService } from 'src/app/_services';
import { Constants } from '..';
import { Auth } from '@angular/fire/auth';

@Injectable()
export class AuthGuard implements CanActivate {
  private auth: Auth = inject(Auth);
  constructor(public authService: AuthService, private router: Router) {}
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean | Observable<boolean | UrlTree> | Promise<boolean> {
    this.authService.onGigyaServiceReady();
    return this.authService.gigyaProcess.pipe(
      switchMap(() =>
        this.authService.logged.pipe(
          map((isAuthenticated) => {
            if (isAuthenticated) {
              return true; // Allow access
            }
            // Redirect to the home page if not authenticated

            return this.router.createUrlTree(
              Constants.Routing.HOMEPAGE.routerLink
            );
          })
        )
      ),
      catchError((error) => {
        console.error('AuthGuard Error:', error);
        // Optionally redirect on error or block navigation
        return [
          this.router.createUrlTree(Constants.Routing.HOMEPAGE.routerLink),
        ];
      })
    );
  }
}
