import {
  Component,
  OnDestroy,
  Input,
  OnInit,
  OnChanges,
  SimpleChanges,
  SimpleChange,
} from '@angular/core';
import { SpinnerService } from './spinner.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-spinner',
  templateUrl: './spinner.component.html',
  styleUrls: ['./spinner.component.scss'],
})
export class SpinnerComponent implements OnDestroy, OnInit {
  // spinner
  public showSpinner = false;

  constructor(private spinner: SpinnerService) {}

  ngOnInit() {}

  ngOnDestroy() {}
}
