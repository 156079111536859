import { Injectable, NgZone } from '@angular/core';
import { SubEvent } from '../_models/SubEvent';

@Injectable({
  providedIn: 'root',
})
export class GlobalPubSubService {
  allowedEvents = ['gigyaServiceReady'];

  private subscriptions: { [key: string]: SubEvent[] } = {};

  constructor(private zone: NgZone) {
    this.allowedEvents.forEach((eventName) => {
      this.subscriptions[eventName] = [];
    });

    (<any>window).fireAngularEvent = (eventName: any, args: any) => {
      if (!this.subscriptions[eventName]) {
        throw new Error('Event has to be defined in the event list.');
      }

      zone.run(() => {
        this.fireEvent(eventName, args);
      });
    };

    (<any>window).subscribeToAngularEvent = (
      eventName: any,
      fn: any,
      context: any
    ) => {
      this.subscribe(eventName, fn, context);
    };
  }

  subscribe(eventName: string, fn: Function, context: any) {
    if (!this.subscriptions[eventName]) {
      throw new Error('Event has to be defined in the event list.');
    }
    let ev = new SubEvent();
    ev.fn = fn;
    ev.context = context;
    this.subscriptions[eventName].push(ev);
  }

  fireEvent(eventName: string, args: any) {
    if (!this.subscriptions[eventName]) {
      throw new Error('Event has to be defined in the event list.');
    }

    this.subscriptions[eventName].forEach((ev) => {
      ev.fn!.apply(ev.context, args);
    });
  }
}
