import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class ApiService {
  constructor(private http: HttpClient) {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
  }

  get<T>(endpoint: string, options?: any): Observable<any> {
    let url = `${environment.host}/u/${endpoint}`;
    return this.http.get<T>(url, options);
  }

  put<T>(endpoint: string, body: any, options?: any): Observable<any> {
    let url = `${environment.host}/u/${endpoint}`;
    return this.http.put<T>(url, body, options);
  }

  post<T>(endpoint: string, body: any, options?: any): Observable<any> {
    let url = `${environment.host}/u/${endpoint}`;
    return this.http.post<T>(url, body, options);
  }

  delete<T>(endpoint: string, options?: any): Observable<any> {
    let url = `${environment.host}/u/${endpoint}`;
    return this.http.delete<T>(url, options);
  }
}
