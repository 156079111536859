<header id="header" class="d-flex justify-content-between align-items-center">
  <div class="d-flex align-items-center">
    <div class="logo">
      <img
        src="../../../assets/images/Humanitas-con-te-def.png"
        alt="Humanitas-con-te"
      />
    </div>
    <div class="divisor"></div>
  </div>
  <nav class="main-nav">
    <div class="d-flex align-items-center">
      <ul class="nav">
        <li>
          <button class="button-menu" (click)="op.toggle($event)">
            <span
              class="hts-icon"
              [ngClass]="{
                'icon-flag-ita': lang == 'it',
                'icon-flag-eng': lang == 'en',
                'icon-flag-rus': lang == 'ru'
              }"
            ></span
            >{{ langAC }}
          </button>
        </li>
        <li *ngIf="auth.user && auth.user.firstName && auth.user.lastName">
          <button class="button-menu user">
            <span class="hts-icon icon-user"></span
            >{{ auth.user.firstName + " " + auth.user.lastName }}
          </button>
        </li>
        <li *ngIf="auth.user">
          <button class="button-menu mobile-button" (click)="showMenu()">
            <i class="mobile-icon pi pi-bars"></i>
          </button>
        </li>
      </ul>
    </div>
  </nav>
</header>

<p-overlayPanel [style]="{ width: '150px' }" #op>
  <ul class="flags-menu">
    <li (click)="changeLanguage('it')">
      <span class="hts-icon icon-flag-ita"></span>{{ "header.ita" | translate }}
    </li>
    <li (click)="changeLanguage('en')">
      <span class="hts-icon icon-flag-eng"></span>{{ "header.eng" | translate }}
    </li>
    <li (click)="changeLanguage('ru')">
      <span class="hts-icon icon-flag-rus"></span>{{ "header.rus" | translate }}
    </li>
  </ul>
</p-overlayPanel>

<p-sidebar
  [(visible)]="showMobileMenu"
  [styleClass]="'humanitas-sidebar'"
  [fullScreen]="true"
  [baseZIndex]="10000"
  [appendTo]="'body'"
>
  <div id="sidebarMobile">
    <ul class="mobile-nav">
      <li *ngIf="auth.user">
        <button class="button-mobile">
          <div>
            <span class="hts-icon icon-user-white"></span
            >{{ auth.user.firstName + " " + auth.user.lastName }}
          </div>
          <i class="icon-mobile-menu arrow-menu"></i>
        </button>
      </li>
    </ul>
  </div>
</p-sidebar>
