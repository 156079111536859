<app-spinner *ngIf="spinner"></app-spinner>

<div id="body-main">
  <app-header></app-header>
  <div id="main">
    <router-outlet></router-outlet>
  </div>
  <app-footer></app-footer>
  <div class="clear"></div>
</div>
